// @ts-ignore (no types available for the package)
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer'

import metaobjects from 'src/data/_static/metaobjects.json'

import type { Maybe } from 'graphql/jsutils/Maybe'
import type { Metafield } from 'src/data/graphql-generated'
import type { Locale } from 'src/enum/Locale'
import type { MetaobjectLocaleMap } from 'src/types/MetaobjectMap'

type MiniMetafield = Pick<Metafield, 'namespace' | 'key' | 'value' | 'reference' | 'references'>

export const getMetaobjectValueFromStored = (id: string, locale: Locale) => {
  const metaobjectsByLocale = (metaobjects as MetaobjectLocaleMap)[locale]

  if (typeof metaobjectsByLocale[id] !== 'undefined') {
    return metaobjectsByLocale[id]
  }
  return undefined
}

const parseValue = (value: any, locale?: Locale): any => {
  if (!value) {
    return null
  }

  if (value === 'true' || value === 'false') {
    return value === 'true'
  }

  let object

  try {
    object = JSON.parse(value)
  } catch {
    // A string value will be assigned if it's not JSON-parsable.
    object = value
  }

  // Try to parse each array item as well.
  if (Array.isArray(object)) {
    return object.map((item) => parseValue(item, locale))
  }

  // Return the value as is, if it's a primitive after JSON.parse.
  if (typeof object === 'number' || typeof object === 'boolean') {
    return object
  }

  // Returns number.
  if (typeof object.scale_min !== 'undefined') {
    return parseFloat(object.value)
  }

  // Render HTML from Rich Text metafield type.
  if (typeof object.type !== 'undefined' && object.type === 'root') {
    return convertSchemaToHtml(object)
  }

  // Map metaobjects.
  if (object.startsWith('gid://shopify/Metaobject') && locale) {
    return getMetaobjectValueFromStored(object, locale)
  }

  return object
}

export const getMetafield = (
  providedMetafields: Array<Maybe<MiniMetafield>>,
  namespace: string,
  key: string,
  locale?: Locale,
): any => {
  if (!providedMetafields) {
    return null
  }

  const metafield = providedMetafields.find((field) => field?.namespace === namespace && field?.key === key)

  if (!metafield) {
    return null
  }

  if ((metafield.references?.nodes || []).length) {
    return metafield.references?.nodes
  }

  if (metafield.reference && Object.keys(metafield.reference).length) {
    return metafield.reference
  }

  return parseValue(metafield.value, locale)
}

export const getMetafields = (
  providedMetafields: Array<Maybe<MiniMetafield>>,
  namespace: string,
  locale?: Locale,
): any[] => {
  if (!providedMetafields) {
    return []
  }

  const metafields = providedMetafields.filter((field) => field?.namespace === namespace)

  return metafields.map((field) => ({
    ...field,
    value: parseValue(field?.value, locale),
  }))
}
